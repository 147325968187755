import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class SectionPortfolio {
    constructor() {
        //this.init();
    }

    init() {

        const sectionPortfolio = document.querySelector('.section-portfolio');

        const _tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionPortfolio,
                invalidateOnRefresh: true,
                scrub: 1,
                id: "id-portfolio",
            },
        });

        // // immagine BG

        const bgHolder = sectionPortfolio.querySelector('.parallax-img-vert__img');
        const heightDiff = bgHolder.offsetWidth - bgHolder.parentElement.offsetWidth;

        _tl.to( bgHolder, {
            x: -heightDiff,
            ease: "none"
        }, 0);

        this.batch(".portfolio-item", {
            interval: 0.5,
            batchMax: 10,
            onEnter: batch => gsap.to(batch, {autoAlpha: 1, x: 0, stagger: 0.15, overwrite: true}),
            onLeave: batch => gsap.set(batch, {autoAlpha: 0, x: 100, overwrite: true}),
            onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, x: 0, stagger: 0.15, overwrite: true}),
            onLeaveBack: batch => gsap.set(batch, {autoAlpha: 0,  x: 100, overwrite: true})
        });

    }

    batch(targets, vars) {
        let varsCopy = {},
            interval = vars.interval || 0.1,
            proxyCallback = (type, callback) => {
                let batch = [],
                    delay = gsap.delayedCall(interval, () => {callback(batch); batch.length = 0;}).pause();
                return self => {
                batch.length || delay.restart(true);
                batch.push(self.trigger);
                vars.batchMax && vars.batchMax <= batch.length && delay.progress(1);
                };
            },
            p;
        for (p in vars) {
            varsCopy[p] = (~p.indexOf("Enter") || ~p.indexOf("Leave")) ? proxyCallback(p, vars[p]) : vars[p];
        }
        gsap.utils.toArray(targets).forEach(target => {
            let config = {};
            for (p in varsCopy) {
            config[p] = varsCopy[p];
            }
            config.trigger = target;
            ScrollTrigger.create(config);
        });
    }
}
