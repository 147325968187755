import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class SectionGallery {
  constructor() {
    this.init();
  }

  init() {
    const selectAll = (e) => document.querySelectorAll(e);

    const tracks = selectAll(".section-gallery");

    tracks.forEach((track, i) => {
      let trackWrapper = track.querySelectorAll(".track");
      let allImgs = track.querySelectorAll(".image");

      let trackWrapperWidth = () => {
        let width = 0;
        trackWrapper.forEach((el) => (width += el.offsetWidth));
        return width;
      };

      gsap.defaults({
        ease: "none",
      });

      let scrollTween = gsap.to(trackWrapper, {
        x: () => -trackWrapperWidth() + window.innerWidth,
        scrollTrigger: {
          trigger: track,
          //invalidateOnRefresh: true,
          markers: true,
          pin: true,
          scrub: 1,
          start: "center center",
          end: () => "+=" + (track.scrollWidth - window.innerWidth),
          invalidateOnRefresh: true,
          id: "id-one",
        },
      });

      

      allImgs.forEach((img, i) => {

        gsap.to(img, {
          x: "0", //change this to -10vw to see parallax image not jump
        });
        gsap.fromTo(
          img,
          {
            x: "-5vw",
          },
          {
            x: "5vw",
            scrollTrigger: {
              trigger: img.parentNode, //.panel-wide
              containerAnimation: scrollTween,
              start: "left right",
              end: "right left",
              scrub: true,
              invalidateOnRefresh: true,
              id: "id-two",
            },
          }
        );
      });
    });
  }
}
