import SectionHero from "../sections/home/section-hero";
import SectionConcept from "../sections/home/section-concept";
import SectionObjectives from "../sections/home/section-objectives";
import SectionResearch from "../sections/home/section-research";
import SectionPortfolio from "../sections/home/section-portfolio";
import SectionGallery from "../sections/home/section-gallery";
import SectionService from "../sections/home/section-service";
import SectionEvents from "../sections/home/section-events";
import SectionTimeline from "../sections/home/section-timeline";
import SectionClosed from "../sections/home/section-closed";

export default class PageHome {
    constructor(root) {
        this.init();
    }

    init() {
        new SectionHero();
        new SectionConcept();
        new SectionGallery();
        new SectionService();
        new SectionEvents();
        new SectionObjectives();
        new SectionResearch();
        new SectionPortfolio();
        new SectionTimeline();
        new SectionClosed();
    }
}
