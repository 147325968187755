import { gsap } from 'gsap';
//import * as YTPlayer from "jquery.mb.ytplayer";


let _root = this;
let _video;
let _playBtn;
let _isStarted = false;

export default class SectionHero {
    constructor() {

        _root = this;

        const _section = document.querySelector('.section-hero');

        _root.heroHolder = _section.querySelector('.video-holder');
        _root.videoContent = _section.querySelector('.hero-content-holder');

        if ('undefined' != typeof _section && null != _section) {
            this.init();
        }


        window.addEventListener('scroll', function(e) {
            _root.onScroll();
        });

        
    }


    onScroll() {

        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

        const _videoHomeHolder = document.querySelector('#video-home-holder');
        _video = _videoHomeHolder.querySelector('#video-home');
        

        if (scrollTop == 0) {
            if (_isStarted) {
                _video.play();
            }
        } else {
            _video.pause();
        }

    }
    

    init() {

        const _videoHomeHolder = document.querySelector('#video-home-holder');
        _video = _videoHomeHolder.querySelector('#video-home');
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: _video.parentElement,
                scrub: true,
                start: "top " + _video.offsetTop,
                end: "+=" + _video.offsetHeight,
            }
        });

        const speed = "-0.5";
        const movement = -(_video.offsetHeight * speed);

        tl.to( _video, { y: movement, ease: "none" }, 0 );



        // let options = {
        //     root: document.querySelector('#main'),
        //     rootMargin: '0px',
        //     threshold: 1.0
        // };

        // let callback = (entries, observer) => {

        //     entries.forEach(entry => {
        //             console.log(entry.isIntersecting);
        //         if (entry.isIntersecting) {
        //             if (_isStarted) {
        //                 entry.target.play();
        //             }
        //         } else {
        //             entry.target.pause();
        //         }
        //     });
        // }

        // let observer = new IntersectionObserver(callback, options);
        // observer.observe(_video);

        
        _playBtn = document.querySelector('#play-video-button');

        _root.addEvent(_playBtn, 'click', _root.onClickPlay);

        _video.onended = (event) => {
            _root.videoContent.style.display = "block";
        };

        _video. onplay = (event) => {
            gsap.to( _playBtn, { autoAlpha: 0, ease: "none" } );
        };

    }

    onClickPlay(el) {
        _video.play();
        _isStarted = true;
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}

/*
(function($) {
	
	$(document).ready(function() {
        if ($('#video-home-holder').length != 0) {
            $("#video-home-params").YTPlayer({
                useNoCookie: true,
                playOnlyIfVisible: true,
                mute: false,
                showControls: false,
                useOnMobile: true,
                ratio: '16/9',
                quality: 'highres',
                optimizeDisplay: true,
                loop: false,
                startAt: 3,
                stopAt: 65,
                autoPlay: true,
                showYTLogo: false,
                realfullscreen: true,
                stopMovieOnBlur: true,
                vol: 100,

            });
            $('#video-home-params').on("YTPEnd",function(e){
                //const tl = gsap.timeline();

                $('.hero-content-holder').show();
                //gsap.to( $('.hero-content'), { autoAlpha: 1, ease: "none" } );

             });
        }	
	});
	
})(jQuery);

*/