import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let triggerState;
let startPin;
let _isStarted;

export default class SectionTimeline {
    constructor() {
        this.init();

        window.addEventListener('resize', () => this.onResize());
    }

    onResize() {
        this.init();
    }

    init() {
        
        if ( !_isStarted ) {

            if (window.innerWidth > 991) {
                triggerState = true;
                startPin = "top top";
            } else {
                triggerState = false;
                startPin = "top bottom";
            }

            const sectionTimeline = document.querySelector('.section-timeline');

            const _tl = gsap.timeline({
                    scrollTrigger: {
                    trigger: sectionTimeline,
                    invalidateOnRefresh: true,
                    pin: triggerState,
                    scrub: 1,
                    start: startPin,
                    toggleActions: "play none none reset",
                    end: () => "+=" + sectionTimeline.scrollHeight,
                    id: "id-timeline",
                },
            });

            // ---------
            const line = sectionTimeline.querySelector('.draw__line');
            _tl.set( line, { height: 0 } );

            // ---------

            // const point_1 = sectionTimeline.querySelector('.draw__point--1');
            // const draw_point_dot_1 = point_1.querySelector('.draw__point__dot');
            // const draw_point_circle_1 = point_1.querySelector('.draw__point__circle');
            // const caption_1 = point_1.querySelector('.caption');
            // _tl.set( draw_point_dot_1, { opacity: 0, scale: 0 } );
            // _tl.set( draw_point_circle_1, { opacity: 0, scale: 0 } );
            // _tl.set( caption_1, { opacity: 0, x: 100 } );

            const point_2 = sectionTimeline.querySelector('.draw__point--2');
            const draw_point_dot_2 = point_2.querySelector('.draw__point__dot');
            const caption_2 = point_2.querySelector('.caption');
            _tl.set( draw_point_dot_2, { opacity: 0, scale: 0 } );
            _tl.set( caption_2, { opacity: 0, x: 100 } );

            const point_3 = sectionTimeline.querySelector('.draw__point--3');
            const draw_point_dot_3 = point_3.querySelector('.draw__point__dot');
            const caption_3 = point_3.querySelector('.caption');
            _tl.set( draw_point_dot_3, { opacity: 0, scale: 0 } );
            _tl.set( caption_3, { opacity: 0, x: 100 } );

            const point_4 = sectionTimeline.querySelector('.draw__point--4');
            const draw_point_dot_4 = point_4.querySelector('.draw__point__dot');
            const draw_point_circle_4 = point_4.querySelector('.draw__point__circle');
            const caption_4 = point_4.querySelector('.caption');
            _tl.set( draw_point_dot_4, { opacity: 0, scale: 0 } );
            _tl.set( draw_point_circle_4, { opacity: 0, scale: 0 } );
            _tl.set( caption_4, { opacity: 0, x: 100 } );
            
            // ---------

            // _tl.add('point_1')
            // .to( draw_point_dot_1, { opacity: 1, scale: 1, ease: "Back.easeOut" }, 'point_1' )
            // .to( draw_point_circle_1, { opacity: 1, scale: 1, ease: "Back.easeOut" }, 'point_1' )
            // .to( caption_1, { opacity: 1, x: 0, ease: "none" }, 'point_1' )
            // .to( line, { height: '33.333%', ease: "none" }, 'point_1' );


            _tl.add('point_2')
            .to( draw_point_dot_2, { opacity: 1, scale: 1, ease: "Back.easeOut" }, 'point_2' )
            .to( caption_2, { opacity: 1, x: 0, ease: "none" }, 'point_2' )
            .to( line, { height: '66.666%', ease: "none" }, 'point_2' );

            _tl.add('point_3')
            .to( draw_point_dot_3, { opacity: 1, scale: 1, ease: "Back.easeOut" }, 'point_3' )
            .to( caption_3, { opacity: 1, x: 0, ease: "none" }, 'point_3' )
            .to( line, { height: '100%', ease: "none" }, 'point_3' );

            _tl.add('point_4')
            .to( draw_point_dot_4, { opacity: 1, scale: 1, ease: "Back.easeOut" }, 'point_4' )
            .to( draw_point_circle_4, { opacity: 1, scale: 1, ease: "Back.easeOut" }, 'point_4' )
            .to( caption_4, { opacity: 1, x: 0, ease: "none" }, 'point_4' );

            _isStarted = true;

        }

    }

}
