import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class SectionClosed {
    constructor() {
        this.init();
    }

    init() {

        const sectionClosed = document.querySelector('.section-closed');

        const _tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionClosed,
                invalidateOnRefresh: true,
                scrub: 1,
                // pin: true,
                // start: "top top",
                // end: () => "+=" + sectionEvents.scrollHeight,
                id: "id-events",
            },
        });

        const bgHolder = sectionClosed.querySelector('.parallax-img__img');
        const heightDiff = bgHolder.offsetHeight - bgHolder.parentElement.offsetHeight;

        _tl.to( bgHolder, {
            y: -heightDiff,
            ease: "none"
        }, 0);

    }

}