import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class SectionEvents {
    constructor() {
        this.init();
    }

    init() {

        const sectionEvents = document.querySelector('.section-events');

        const _tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionEvents,
                invalidateOnRefresh: true,
                scrub: 1,
                // pin: true,
                // start: "top top",
                // end: () => "+=" + sectionEvents.scrollHeight,
                id: "id-events",
            },
        });

        // const block__1 = sectionEvents.querySelector('.event-block__1');
        // _tl.set( block__1, { opacity: 0, y: 100 } );

        // _tl.add('block__1')
        // .to( block__1, { opacity: 1, y: 0, ease: "none" }, 'block__1' )

        // _tl.set( eventBlock, { opacity: 0 } );

        // _tl.to( eventBlock, { 
        //     opacity: 1,
        //     ease: "none" 
        // }, 0 );

        // // immagine 1p

        // const layer = sectionEvents.querySelector('.parallax-concept-item');
        // const speed = 0.5;
        // const movement = (layer.offsetHeight * speed);

        // _tl.to( layer, { 
        //     y: movement,
        //     ease: "none" 
        // }, 0 );

        // // immagine BG

        const bgHolder = sectionEvents.querySelector('.parallax-img__img');
        const heightDiff = bgHolder.offsetHeight - bgHolder.parentElement.offsetHeight;

        _tl.to( bgHolder, {
            y: -heightDiff,
            ease: "none"
        }, 0);


        const _eventBlock = sectionEvents.querySelectorAll('.event-block');

        _eventBlock.forEach(target => {
            gsap.set(target, { autoAlpha: 0, y: 100 });
        });

        // const block__1 = sectionEvents.querySelector('.event-block__1');
        // gsap.from(block__1, {
        //     scrollTrigger: {
        //         trigger: block__1,
        //         start: "20% 75%",
        //         toggleClass: "_active",
        //         toggleActions: 'play none play none',
        //         once: true
        //     }
        // })

        // _eventBlock.forEach(target => {
        //     let config = {};
        //     config.trigger = target;
        //     config.start = "20% 75%";
        //     config.toggleClass = "_active";
        //     config.toggleActions = "play none none reverse";
        //     // config.once = true;

        //     ScrollTrigger.create(config);
        // });

        this.batch(".event-block", {
            interval: 2,
            batchMax: 3,
            onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.5, overwrite: true}),
            //onLeave: batch => gsap.set(batch, {autoAlpha: 0, y: 100, overwrite: true}),
            onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.5, overwrite: true}),
           // onLeaveBack: batch => gsap.set(batch, {autoAlpha: 0,  y: 100, overwrite: true})
        });

    }

    batch(targets, vars) {
        let varsCopy = {},
            interval = vars.interval || 0.1,
            proxyCallback = (type, callback) => {
                let batch = [],
                    delay = gsap.delayedCall(interval, () => {callback(batch); batch.length = 0;}).pause();
                return self => {
                batch.length || delay.restart(true);
                batch.push(self.trigger);
                vars.batchMax && vars.batchMax <= batch.length && delay.progress(1);
                };
            },
            p;
        for (p in vars) {
            varsCopy[p] = (~p.indexOf("Enter") || ~p.indexOf("Leave")) ? proxyCallback(p, vars[p]) : vars[p];
        }
        gsap.utils.toArray(targets).forEach(target => {
            let config = {};
            for (p in varsCopy) {
            config[p] = varsCopy[p];
            }
            config.trigger = target;
            ScrollTrigger.create(config);
        });
    }
}


// export default class SectionEvents {
//     constructor() {
//         this.init();
//     }

//     init() {

//         const sectionEvents = document.querySelector('.section-events');
//         const bgHolder = sectionEvents.querySelector('.bg-holder');

//         const _aBg = bgHolder.querySelectorAll('.bg');

//         let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

//         _aBg.forEach((section, i) => {

//         // Give the backgrounds some random images
        
//         // the first image (i === 0) should be handled differently because it should start at the very top.
//         // use function-based values in order to keep things responsive
//         gsap.fromTo(section, {
//             backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px"
//         }, {
//             backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
//             ease: "none",
//             scrollTrigger: {
//             trigger: section,
//             start: () => i ? "top bottom" : "top top", 
//             end: "bottom top",
//             scrub: true,
//             invalidateOnRefresh: true // to make it responsive
//             }
//         });

//         });

//     }
// }
