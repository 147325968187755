import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let triggerState;
let _isStarted;

export default class SectionConcept {
    constructor() {
        this.init();

        window.addEventListener('resize', () => this.onResize());
    }

    onResize() {
        this.init();
    }

    init() {

        const sectionConcept = document.querySelector('.section-concept');
        const container = sectionConcept.querySelector('.concept-panel-container');
        const trackWrapper = container.querySelectorAll(".panel");

        if (window.innerWidth > 991) triggerState = true;
        else triggerState = false;

        if ( triggerState && !_isStarted) {

            const _tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionConcept,
                    invalidateOnRefresh: true,
                    pin: true,
                    scrub: 1,
                    //snap: 1 / (trackWrapper.length - 1),
                    start: "top top",
                    end: () => "+=" + sectionConcept.scrollHeight,
                    id: "id-concept",
                },
            });

            _tl.to( trackWrapper, { 
                yPercent: -100 * (trackWrapper.length - 1),
                ease: "none" 
            }, 0 );

            // immagine 1

            // const image__1 = sectionConcept.querySelector('.image__1');

            // _tl.to( image__1, { 
            //     y: (image__1.offsetHeight * -0.2),
            //     ease: "none" 
            // }, 0 );

            // immagine 2

            // const image__2 = sectionConcept.querySelector('.image__2');

            // _tl.to( image__2, { 
            //     y: (image__2.offsetHeight * -0.2),
            //     ease: "none" 
            // }, 0 );

            // immagine 3

            // const image__3 = sectionConcept.querySelector('.image__3');

            // _tl.to( image__3, { 
            //     y: (image__3.offsetHeight * -0.2),
            //     ease: "none" 
            // }, 0 );

            // immagine 4

            // const image__4 = sectionConcept.querySelector('.image__4');

            // _tl.to( image__4, { 
            //     y: (image__4.offsetHeight * -0.2),
            //     ease: "none" 
            // }, 0 );

        }

        _isStarted = true;

    }
}
